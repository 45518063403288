html {
	scroll-behavior: smooth;
	/* font-family: "Oswald", sans-serif; */
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	color: rgb(0 0 0 / 65%);
}

b,
strong {
	font-weight: 600;
}

.robotoSlab {
	font-family: "Roboto Slab", serif !important;
}

.robotoCondensed {
	font-family: "Roboto Condensed", sans-serif !important;
}

body .btn {
	font-size: 17px;
}

#Launch {
	margin-top: 0;
}

body .btn-large {
	font-size: 20px;
	font-weight: 500 !important;
	line-height: 50px;
	height: 50px;
	padding: 0 20px;
	text-transform: uppercase;
}

.circle {
	border-radius: 50%;
	overflow: hidden;
}

body .btn,
body .btn-large,
body .btn-small {
	font-family: "Montserrat", sans-serif;
	text-decoration: none;
	color: #fff;
	background-color: #c41c1c;
	text-align: center;
	letter-spacing: 0.5px;
	transition: background-color 0.2s ease-out;
	cursor: pointer;
	box-shadow: none;
	border-radius: 10px;
	font-weight: 300;
	text-transform: none;
}

body .btn-gold {
	background-color: #a09468 !important;
	margin: 10px;
	border-radius: 30px;
	font-weight: 300;
	font-size: 20px;
	line-height: 50px;
	height: 50px;
	padding: 0 20px;
}

body .btn:hover,
body .btn-large:hover,
body .btn-small:hover {
	background-color: black !important;
	-webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14),
		0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
		0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

.btn:focus,
.btn-large:focus,
.btn-small:focus,
.btn-floating:focus {
	background-color: #c41c1c !important;
}

.hvr-underline-reveal::before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	right: 0;
	bottom: 0;
	background: #c41c1c;
	height: 4px;
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-underline-reveal {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hvr-underline-reveal:hover::before,
.hvr-underline-reveal:focus::before,
.hvr-underline-reveal:active::before {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

footer {
	font-family: "Montserrat", sans-serif;
}

.container {
	margin: 0 auto;
	max-width: 100%;
	width: 100%;
	padding: 0 2.25%;
}

.container.inner {
	margin: 0 auto;
	max-width: 1200px;
	width: 95%;
}

.container h1 {
	font-weight: 300;
	font-size: 2.5rem;
	color: #a09468;
}

.container h2 {
	font-size: 2rem;
}

.container h3 {
	font-size: 1.4rem;
	margin: 10px 0;
}

.container h4 {
	font-weight: bold;
	font-size: 2rem;
}

a {
	color: #144b48;
}

.container p {
	font-size: 18px;
}

nav .brand-logo {
	left: 50%;
	transform: translateX(-50%);
}

nav ul a {
	font-family: "Oswald", sans-serif;
	font-size: 1.5rem;
	padding: 0;
	margin: 0 15px;
	line-height: 40px;
	color: #8f8f8f;
	text-transform: uppercase;
	font-weight: 300;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}

nav ul a:hover {
	background-color: transparent;
	color: #c41c1c;
}

.container footer li {
	display: inline-block;
	margin: 5px 20px;
}

.container footer li a {
	color: #fff;
	text-transform: uppercase;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}

.container footer li a:hover {
	color: black;
}

::placeholder {
	color: #000;
}

/* nav,
nav .nav-wrapper i,
nav a.sidenav-trigger,
nav a.sidenav-trigger i {
	display: inline;
	float: right;
	color: #a09468;
	height: 45px;
	line-height: 42px;
	font-size: 45px;
	border-radius: 5px;
	border: 1px solid #a09468;
}
nav .sidenav-trigger {
	margin: 30px 10px;
} */

.sidenav li > a {
	font-family: "Oswald", sans-serif;
	font-size: 1.5rem;
	color: #8f8f8f;
	text-align: center;
	/* border-bottom: 1px solid; */
}

.sidenav li > a:hover {
	background-color: transparent;
	color: #c41c1c;
}

::placeholder {
	color: #848484;
}

select {
	background-color: rgb(255 255 255);
	width: 100%;
	padding: 5px;
	border: 1px solid #f2f2f2;
	border-radius: 2px;
	height: 2.7rem;
}

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea {
	background-color: white;
	border: none;
	border-bottom: 1px solid #9e9e9e;
	border-radius: 2px;
	outline: none;
	height: 2.7rem;
	width: 92%;
	font-size: 16px;
	margin: 0 0 20px 0;
	padding: 0 4%;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	-webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
	transition: border 0.3s, -webkit-box-shadow 0.3s;
	transition: box-shadow 0.3s, border 0.3s;
	transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
}

input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea:focus:not([readonly]) {
	border-bottom: 1px solid #ffa630;
	-webkit-box-shadow: 0 1px 0 0 #ffa630;
	box-shadow: 0 1px 0 0 #ffa630;
}

input:not([type]):focus:not([readonly]) + label,
input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
input[type="password"]:not(.browser-default):focus:not([readonly]) + label,
input[type="email"]:not(.browser-default):focus:not([readonly]) + label,
input[type="url"]:not(.browser-default):focus:not([readonly]) + label,
input[type="time"]:not(.browser-default):focus:not([readonly]) + label,
input[type="date"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime-local"]:not(.browser-default):focus:not([readonly])
	+ label,
input[type="tel"]:not(.browser-default):focus:not([readonly]) + label,
input[type="number"]:not(.browser-default):focus:not([readonly]) + label,
input[type="search"]:not(.browser-default):focus:not([readonly]) + label,
textarea:focus:not([readonly]) + label {
	color: #ffa630;
}

[type="checkbox"] + span:not(.lever):before,
[type="checkbox"]:not(.filled-in) + span:not(.lever):after {
	border: 2px solid black;
}

[type="checkbox"]:checked + span:not(.lever):before {
	border-right: 2px solid black;
	border-bottom: 2px solid black;
}

.input-field .helper-text {
	position: absolute;
	min-height: 18px;
	display: block;
	font-size: 12px;
	bottom: 0px;
}

.redBtn:hover {
	/* background-color: black !important; */
	-webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14),
		0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
		0 3px 1px -1px rgba(0, 0, 0, 0.2);
	/* padding-bottom: 5px; */
}

.redBtn {
	text-transform: none;
	padding: 0px;
	background-color: #c41c1c;
	border-radius: 50%;
	border: 2px solid white;
	transition: all 0.2s ease-out;
	color: white;
	font-size: 18px;
	width: 100px;
	height: 100px;
	line-height: 100px;
}

button:focus {
	outline: none;
	background-color: #c41c1c;
}

.ssIcon button,
.ssIcon a {
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
	color: #282828;
	width: 50px;
	height: 50px;
	vertical-align: middle;
	line-height: 44px;
	border-radius: 50%;
	font-size: 25px;
	margin: 10px;
	text-align: center;
	border: 1px solid;
	display: inline-block;
}

.ssIcon button:hover {
	background-color: #000;
}

.fbIcon:hover {
	background-color: #3a5794;
	border: 1px solid #3a5794;
	color: white;
}

.twIcon:hover {
	background-color: #1c9cea;
	border: 1px solid #1c9cea;
	color: white;
}

.igIcon:hover {
	background-color: #da2f69;
	border: 1px solid #da2f69;
	color: white;
}

.eIcon:hover {
	background-color: #ffa630;
	border: 1px solid #ffa630;
	color: white;
}

hr.fadeEnd {
	border: 0;
	height: 1px;
	background-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 0),
		rgba(0, 0, 0, 0.75),
		rgba(0, 0, 0, 0)
	);
}

.valignAdj {
	display: block;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

#ellipse {
	position: absolute;
	width: 10%;
	height: 300px;
	margin: 0 auto;
	background: white;
	border-radius: 0%;
	left: 50%;
	transform: translateX(-50%);
	display: none;
}

.ellipseTop {
	top: -30%;
	-moz-box-shadow: inset -18px 14px #3a3a3a54;
	-webkit-box-shadow: inset -18px 14px #3a3a3a54;
	box-shadow: inset 0px -18px 14px #3a3a3a54;
}

.ellipseBtm {
	bottom: -30%;
	-moz-box-shadow: inset 18px 14px #3a3a3a54;
	-webkit-box-shadow: inset 18px 14px #3a3a3a54;
	box-shadow: inset 0px 18px 14px #3a3a3a54;
}

.ellipseFooter {
	top: -10%;
	box-shadow: 0px -18px 14px #3a3a3a54;
	background-color: #a09468 !important;
	/* position: relative !important; */
}

#rectangle {
	background-color: rgb(160, 148, 104);
	width: 100%;
	min-height: 500px;
	padding: 4rem 0 15rem;
	color: white;
}

.arrow-down {
	width: 0;
	height: 0;
	border-left: 30px solid transparent;
	border-right: 30px solid transparent;
	border-top: 30px solid #c41c1c;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	top: -10px;
}

.goldBg {
	background-color: rgb(160, 148, 104);
}

.goldTxt {
	color: rgb(160, 148, 104);
}

.noBgCollapsable {
	box-shadow: none;
	border: 0;
	font-size: 17px;
	color: #444;
}

.noBgCollapsable li li:first-child {
	padding-top: 0;
}

.noBgCollapsable li li li {
	list-style-type: disc;
	margin-left: 30px;
	padding-left: 0;
}

.noBgCollapsable li li {
	border: 0;
	padding: 10px 0 0 40px;
}

.noBgCollapsable li {
	border-bottom: 1px solid #ddd;
	-webkit-transition: 0.25s;
	transition: 0.25s;
}

.noBgCollapsable li:last-child {
	border-bottom: 0px;
}

.noBgCollapsableHead {
	display: block;
	border: 0;
	background: transparent;
	color: white;
	text-align: left;
	padding: 15px 0;
	text-transform: uppercase;
	font-weight: 400;
	transition: all 0.28s;
}

.noBgCollapsableHead i {
	margin: 0;
}

.noBgCollapsableHead:hover {
	color: #653800;
}

.noBgCollapsableBody {
	border: 0;
	padding: 10px 0px;
	margin: 0 -10px;
}

.homeLanding {
	height: auto;
}

.star {
	display: none;
}

.scrollTop {
	display: none;
}

.input-field > label:not(.label-icon).active,
textarea.materialize-textarea:focus:not([readonly]) + label {
	/* color: white; */
	margin-top: -5px;
}

.input-field > label {
	color: #777777;
	left: "2rem";
}

.blog-entry {
	width: 100%;
	margin-bottom: 3em;
}

.blog-entry .img {
	display: block;
	height: 150px;
	width: 150px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
}

@media (max-width: 767.98px) {
	.blog-entry .img {
		margin: 0 auto;
		margin-bottom: 20px;
	}
}

.recent-posts .flex .img {
	margin-right: 20px;
}

.recent-posts .flex .text {
}

.recent-posts .flex h3 {
	font-size: 16px;
}

.post-page h1 {
	margin-top: 0;
}

.post-page .content img {
	max-width: 100%;
}

.comment-list .comment {
	margin-bottom: 20px;
	border-bottom: 1px solid #ddd;
	padding-bottom: 25px;
}

.comment-list .comment:last-child {
	border-bottom: 0px solid #ddd;
}

.comment-list li .comment-body .meta {
	text-transform: uppercase;
	font-size: 13px;
	letter-spacing: 0.1em;
	color: #ccc;
}

.recent-posts .flex {
	margin-bottom: 20px;
}

.blog-entry .text {
	position: relative;
	z-index: 0;
	width: 100%;
	padding-left: 2rem;
	/* width: calc(100% - 150px); */
}

.flex.fill > div {
	flex: 1;
}

div.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 50px;
	padding: 0 10px 10px 0;
	float: left;
	width: 70px;
	margin-top: 0px;
	text-align: center;
}

.ssIcon button,
.ssIcon a {
	cursor: pointer;
	display: inline-block;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
	color: white;
	width: 45px;
	height: 45px;
	vertical-align: middle;
	line-height: 45px;
	border-radius: 50%;
	font-size: 25px;
	margin-bottom: 7px;
	text-align: center;
	background-color: rgb(160, 148, 104);
	border: 0;
}

.ssIcon a:hover {
	background-color: black !important;
}

@media (max-width: 767.98px) {
	.blog-entry .text {
		width: 100%;
		text-align: center;
		padding-left: 0;
	}

	.flex {
		justify-content: space-evenly;
	}
}

.blog-entry .text h3 {
	font-weight: 400;
	font-size: 30px;
}

.blog-entry .text h3 a {
	color: #000000;
}

.blog-entry .text .meta-wrap {
	width: 100%;
	display: block;
}

.blog-entry .text .meta-wrap .meta span {
	font-size: 14px;
	margin: 0 10px 0 0;
	padding: 0 10px;
	color: #bfbfbf;
}

.blog-entry .text .meta-wrap .meta span a {
	color: #000000;
}

.flex {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}

.prtTitle {
	color: rgb(160, 148, 104);
	font-weight: 300;
	font-size: 1.6rem !important;
}

.displayAdj,
.displayRecipeAdj {
	display: block;
	max-height: none;
}

.productInfoAdj {
	margin: 50px 0 !important;
}
.cartNumItem {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	margin: -11px 5px -11px 10px;
	height: 45px;
	font-size: 10px;
	color: gray;
}

/* Tablet style m-size */
@media only screen and (min-width: 601px) {
	.d-md-flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}

	.scrollTop {
		display: flex;
	}

	.homeLanding {
		/* height: 75vh; */
	}

	#ellipse {
		width: 115%;
		border-radius: 50%;
	}

	#rectangle {
		padding: 8rem 0 15rem;
	}

	#Launch {
		margin-top: -20px;
	}

	.displayAdj {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		overflow: hidden;
	}
}

/* Desktop style l-size*/
@media only screen and (min-width: 993px) {
	.scrollTop {
		display: flex;
	}

	.star {
		display: unset;
	}

	#Launch {
		margin-top: -20px;
	}

	.container h1 {
		font-size: 3.5rem;
	}

	#ellipse {
		width: 115%;
		border-radius: 50%;
		display: unset;
	}

	.container {
		padding: 0 6.25%;
	}

	figure.effect-lily figcaption > div {
		height: 25%;
	}

	.valignAdj {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.homeLanding {
		/* height: 77vh; */
	}

	.container.inner {
		padding: 0 3%;
	}

	.displayAdj {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		overflow: hidden;
	}
}

.blog-entry .square {
	height: 150px;
	width: 150px;
	padding: 0;
}

.square .sq-content {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.square {
	width: 100%;
	padding-bottom: 100%;
	position: relative;
	/* background-color: #2980b9; */
}

figure {
	position: relative;
	/* float: left; */
	margin: 10px 1%;
	/* min-width: 320px;
	max-width: 480px;
	max-height: 360px; */
	width: 100%;
	/* background: rgb(235, 235, 235); */
	text-align: center;
	cursor: pointer;
	margin: 0;
}

figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

figure figcaption {
	/* position: absolute; */
	margin: 0;
	padding: 0;
	width: 100%;
}

figure figcaption .card {
	color: #404040;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	padding: 1rem;
	width: 80%;
	margin: 0 auto;
}

figure figcaption::before,
figure figcaption::after {
	pointer-events: none;
}

figure figcaption,
figure figcaption > a {
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

figure h2 {
	word-spacing: -0.15em;
	font-weight: 300;
}

figure h2 span {
	font-weight: 800;
}

figure h2,
figure p {
	margin: 0;
}

figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

/* Individual effects */

/*---------------*/
/***** Bubba *****/
/*---------------*/

/* figure.effect-bubba {
	background: #9e5406;
} */

figure.effect-bubba img {
	opacity: 0.8;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}

figure.effect-bubba:hover img {
	opacity: 1;
}

@media only screen and (min-width: 601px) {
	.product-title {
		font-size: 17px !important;
	}
}

@media only screen and (min-width: 993px) {
	.product-title {
		font-size: 20px !important;
	}
}

/* figure.effect-bubba figcaption::before,
figure.effect-bubba figcaption::after {
	position: absolute;
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	content: "";
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
}

figure.effect-bubba figcaption::before {
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
	-webkit-transform: scale(0, 1);
	transform: scale(0, 1);
}

figure.effect-bubba figcaption::after {
	border-right: 1px solid #fff;
	border-left: 1px solid #fff;
	-webkit-transform: scale(1, 0);
	transform: scale(1, 0);
}

figure.effect-bubba h2 {
	padding-top: 0;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0, -20px, 0);
	transform: translate3d(0, -20px, 0);
	opacity: 0;
	font-family: "astronoutregular";
	font-size: 8rem;
} */

figure.effect-bubba p {
	padding: 0px;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0, 20px, 0);
	transform: translate3d(0, 20px, 0);
	font-size: 60px;
	color: rgb(160, 148, 104);
	position: absolute;
	right: 10px;
	z-index: 100;
}

figure.effect-bubba p span {
	position: absolute;
	right: 15px;
	color: white;
	font-size: 18px;
	top: 33px;
}

/* figure.effect-bubba:hover figcaption::before,
figure.effect-bubba:hover figcaption::after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
} */

figure.effect-bubba:hover h2,
figure.effect-bubba:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.square {
	width: 100%;
	padding-bottom: 100%;
	position: relative;
}

.closeBtn {
	cursor: pointer;
	position: absolute;
	z-index: 100;
	position: absolute;
	right: -5px;
	top: -5px;
}

.closeBtn i {
	position: absolute;
	right: 15px;
	top: 15px;
	color: white;
	font-size: 30px;
}

.flex-row .f-column.c2 {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 50%;
	max-width: 400px;
	margin: 0 auto;
}

@media only screen and (max-width: 1100px) {
	.row .flex-row .f-column.c2 {
		max-width: 80%;
	}
}

@media only screen and (max-width: 600px) {
	.row .flex-row .f-column.c2 {
		max-width: 100%;
	}
}

.flex-row .product-spacer {
	padding: 1rem 1rem 0;
}

.flex-row {
	display: flex;
	flex-wrap: wrap;
}

.product-wrapper {
	transition: all 0.23s;
	padding: 20px !important;
}

.product-wrapper:hover {
	-webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
		0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
	box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
		0 2px 4px -1px rgba(0, 0, 0, 0.3);
}
