.card.horizontal {
	border: 1px solid #5e5e5e;
	display: inline-block;
}
.card .card-action {
	padding: 0;
}
.full-price {
	line-height: 50px;
	background-color: #e6e6e6;
	color: black;
	padding: 0 10px;
	font-weight: bold;
}
.price {
	line-height: 50px;
	color: black;
	padding: 0 10px;
}
.totalText {
	font-weight: bold;
	/* background: #d2d2d2; */
	border-top: 1px solid;
	padding: 20px 0px;
}
.remove {
	position: absolute;
	right: 0;
	top: 5px;
	color: rgb(168, 168, 168);
	font-size: 13px;
	/* border-left: 1px solid rgb(104, 104, 104);
	border-bottom: 1px solid rgb(104, 104, 104); */
	padding: 5px 10px;
	text-decoration: underline;
}
.remove:hover {
	color: rgb(98, 98, 98);
}

.singleProductSummary {
	height: 191px;
	border: 1px solid;
	overflow: hidden;
}
.singleProductImg {
	/* width: 16%; */
	height: 190px;
	float: left;
	margin-right: 10px;
}
#product-front,
#product-back {
	background: #fff;
	position: relative;
	-webkit-transition: all 100ms ease-out;
	-moz-transition: all 100ms ease-out;
	-o-transition: all 100ms ease-out;
	transition: all 100ms ease-out;
}
#product-back {
	display: none;
	transform: rotateY(180deg);
}
#product-card.animate #product-back,
#product-card.animate #product-front {
	top: 0px;
	left: 0px;
	-webkit-transition: all 100ms ease-out;
	-moz-transition: all 100ms ease-out;
	-o-transition: all 100ms ease-out;
	transition: all 100ms ease-out;
}
#product-card img {
	width: 100%;
}
#product-card .stats-spacer {
	height: 68px;
}
#product-card {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	transform-style: preserve-3d;
	-webkit-transition: 100ms ease-out;
	-moz-transition: 100ms ease-out;
	-o-transition: 100ms ease-out;
	transition: 100ms ease-out;
}
#product-card.animate {
	/* box-shadow:0px 13px 21px -5px rgba(0, 0, 0, 0.3); */
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
	-webkit-transition: 100ms ease-out;
	-moz-transition: 100ms ease-out;
	-o-transition: 100ms ease-out;
	transition: 100ms ease-out;
}
.stats-container {
	background: #fff;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	border-left: grey 1px solid;
	border-right: grey 1px solid;
	border-bottom: grey 1px solid;
	padding: 10px 10px;
	-webkit-transition: all 200ms ease-out;
	-moz-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
}
#product-card.animate .stats-container {
	/* top:272px; */
	-webkit-transition: all 200ms ease-out;
	-moz-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
}
.stats-container .product_name {
	font-size: 16px;
	color: rgb(14, 118, 188);
	display: block;
}
.stats-container p {
	font-size: 16px;
	color: #b1b1b3;
	margin: 0;
	padding: 0;
}
.stats-container .product_price,
.product_price {
	color: #48cfad;
	font-size: 18px;
	font-weight: 600;
}
.stats-container .product_price_sale,
.product_price_sale {
	color: #d83c3c;
	font-size: 18px;
	font-weight: 600;
}
.lineThrough {
	-webkit-text-decoration-line: line-through; /* Safari */
	text-decoration-line: line-through;
}
.image_overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #48daa1;
	opacity: 0;
}
#product-card.animate .image_overlay {
	opacity: 0.7;
	-webkit-transition: all 200ms ease-out;
	-moz-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
}
.product-options {
	padding: 2px 0 0;
}
.product-options strong {
	font-weight: 700;
	color: #393c45;
	font-size: 14px;
}
.product-options span {
	color: #969699;
	font-size: 14px;
	display: block;
	margin-bottom: 8px;
}
#view_details {
	position: absolute;
	top: 40%;
	left: 50%;
	margin-left: -85px;
	border: 2px solid #fff;
	color: #fff;
	font-size: 19px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 700;
	padding: 10px 0;
	width: 172px;
	opacity: 0;
	-webkit-transition: all 200ms ease-out;
	-moz-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
}
#view_details:hover {
	background: #fff;
	color: #48cfad;
	cursor: pointer;
}
#product-card.animate #view_details {
	opacity: 1;
	width: 152px;
	font-size: 15px;
	margin-left: -75px;
	top: 40%;
	-webkit-transition: all 200ms ease-out;
	-moz-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
}
div.colors div {
	margin-top: 3px;
	width: 15px;
	height: 15px;
	margin-right: 5px;
	float: left;
}
div.colors div span {
	width: 15px;
	height: 15px;
	display: block;
	border-radius: 50%;
}
div.colors div span:hover {
	width: 17px;
	height: 17px;
	margin: -1px 0 0 -1px;
}
div.c-blue span {
	background: #6e8cd5;
}
div.c-red span {
	background: #f56060;
}
div.c-green span {
	background: #44c28d;
}
div.c-white span {
	background: #fff;
	width: 14px;
	height: 14px;
	border: 1px solid #e8e9eb;
}
div.shadow {
	width: 335px;
	height: 520px;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	display: none;
	background: -webkit-linear-gradient(
		left,
		rgba(0, 0, 0, 0.1),
		rgba(0, 0, 0, 0.2)
	);
	background: -o-linear-gradient(right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
	background: -moz-linear-gradient(
		right,
		rgba(0, 0, 0, 0.1),
		rgba(0, 0, 0, 0.2)
	);
	background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
}

.search-wrapper {
	height: 40px;
	margin: 20px 0;
	padding-right: 10px;
}
.search-wrapper input {
	padding: 10px 15px;
	float: left;
	border: 0;
	/* background: #EEE; */
	border-radius: 3px 0 0 3px;
}

.search-wrapper input:focus {
	outline: 0;
	/* background: #fff; */
	box-shadow: 0 0 2px #05b946 inset;
}

.search-wrapper input::-webkit-input-placeholder {
	color: #999;
	font-weight: normal;
	font-style: italic;
}

.search-wrapper input:-moz-placeholder {
	color: #999;
	font-weight: normal;
	font-style: italic;
}

.search-wrapper input:-ms-input-placeholder {
	color: #999;
	font-weight: normal;
	font-style: italic;
}

/* Form submit button */
.search-wrapper button {
	overflow: visible;
	position: absolute;
	right: 0;
	border: 0;
	padding: 0;
	cursor: pointer;
	height: 46px;
	width: 46px;
	color: white;
	text-transform: uppercase;
	background: #d83c3c;
	border-radius: 0 3px 3px 0;
	/* text-shadow: 0 -1px 0 rgba(0, 0, 0, .3); */
	font-size: 22px;
}

.search-wrapper button:hover {
	background: #e9e9e9;
}

.search-wrapper button:active,
.search-wrapper button:focus {
	/* background: #c42f2f; */
	outline: 0;
}

.search-wrapper button:before {
	/* left arrow */
	content: "";
	position: absolute;
	border-width: 8px 8px 8px 0;
	border-style: solid solid solid none;
	border-color: transparent #d83c3c transparent;
	top: 15px;
	left: -6px;
}

.search-wrapper button:hover:before {
	border-right-color: #e9e9e9;
}

.search-wrapper button:focus:before,
.search-wrapper button:active:before {
	border-right-color: #c42f2f;
}

.search-wrapper button::-moz-focus-inner {
	/* remove extra button spacing for Mozilla Firefox */
	border: 0;
	padding: 0;
}
.categories-container {
	/* padding-right:10px; */
}
.categories-container ul {
	margin: 0;
	padding: 0;
	border-top: 1px solid #505050;
}
.categories-container ul:last-child {
	border-bottom: 1px solid #505050;
}
.categories-container ul ul {
	background: white;
}
.categories-container ul ul li a {
	background: white;
}
.categories-container ul ul li:last-child a {
	border: 0;
}
.categories-container li {
	list-style: none;
	margin: 0;
	padding: 0;
}
.categories-container li a.active {
	background: #52be8d;
	color: white;
	/* border:1px solid white; */
}
.categories-container li a {
	display: block;
	line-height: 50px;
	/* background: #f2f2f2; */
	padding: 0 10px;
	color: rgb(14, 118, 188);
	text-transform: uppercase;
	border: 0px solid grey;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	font-size: 14px;
	font-weight: bold;
}
.categories-container li li a {
	font-weight: normal;
	font-size: 14px;
}
.categories-container li a.sub-category {
	margin: 0 15px;
}
.categories-container li a:hover {
	background: #aaa;
	color: black;
	text-decoration: none;
}
/* Shrink wrap strategy 2 */
.easyzoom {
	display: inline-block;
	margin-bottom: 5px;
}
.easyzoom img {
	vertical-align: bottom;
}

.slider {
	overflow: hidden;
	margin: 5px 0 0 0;
	position: relative;
	width: 100%;
}
.slider ul {
	position: relative;
	width: 100px;
	margin: 0;
	padding: 0;
}
.slider ul li img {
	width: auto;
	height: 100%;
}
.slider ul li {
	float: none;
	list-style: outside none none;
	text-align: center;
	position: relative;
	width: auto;
	margin-right: 5px;
}

.slider ul li span {
	display: inline-block;
	vertical-align: middle;
	width: 100px;
	height: 100px;
	background: black;
}
.slider-arrow {
	position: absolute;
	top: 40px;
	width: 25px;
	height: 25px;
	color: #fff;
	text-align: center;
	text-decoration: none;
	border-radius: 50%;
}
.sa-left {
	left: 10px;
}
.sa-right {
	right: 10px;
}
.rgba-white {
	background: rgba(0, 0, 0, 0.5);
}
.coupon {
	position: relative;
}
.coupon input[type="text"] {
	/* border-top-color: white;
   border-left-color: white; */
}
.coupon input {
	margin: 0;
	height: 50px;
	padding: 0 14px;
	border-radius: 5px 5px 0 0;
	text-transform: uppercase;
}
.coupon span {
	font-size: 30px;
	line-height: 40px;
	padding-left: 20px;
}
.coupon .coupon-btn {
	cursor: pointer;
	color: white;
	font-size: 20px;
	text-align: center;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}
#cart {
	width: 100%;
	min-height: 500px;
}

#cart h1 {
	font-weight: 300;
}

#cart a {
	color: #53b5aa;
	text-decoration: none;

	-webkit-transition: color 0.2s linear;
	-moz-transition: color 0.2s linear;
	-ms-transition: color 0.2s linear;
	-o-transition: color 0.2s linear;
	transition: color 0.2s linear;
}

#cart a:hover {
	color: #000;
}

.product.removed {
	margin-left: 980px !important;
	opacity: 0;
}

.product {
	border: 1px solid #686868;
	margin: 20px 0;
	width: 100%;
	height: 191px;
	position: relative;

	-webkit-transition: margin 0.2s linear, opacity 0.2s linear;
	-moz-transition: margin 0.2s linear, opacity 0.2s linear;
	-ms-transition: margin 0.2s linear, opacity 0.2s linear;
	-o-transition: margin 0.2s linear, opacity 0.2s linear;
	transition: margin 0.2s linear, opacity 0.2s linear;
}

.product img {
	max-width: 100%;
	max-height: 100%;
}
.product header,
.product .product-content {
	background-color: #fff;
	border: 1px solid #686868;
	border-style: none none solid none;
	float: left;
}

.product header {
	background: #fff;
	margin: 0;
	overflow: hidden;
	padding: 0;
	text-align: center;
	position: relative;
	/* width: 14%; */
	height: 190px;
}

.product header:hover img {
	opacity: 0.7;
}

.product header:hover h3 {
	bottom: 73px;
	background: rgba(83, 181, 170, 1);
	color: #fff;
}

.product header h3 {
	background: rgba(83, 181, 170, 0.3);
	color: #000;
	font-size: 22px;
	font-weight: 300;
	line-height: 49px;
	margin: 0;
	padding: 0 30px;
	position: absolute;
	bottom: -1px;
	right: 0;
	left: 0;

	-webkit-transition: bottom 0.2s linear;
	-moz-transition: bottom 0.2s linear;
	-ms-transition: bottom 0.2s linear;
	-o-transition: bottom 0.2s linear;
	transition: bottom 0.2s linear;
}

.remove {
	cursor: pointer;
}

.product .product-content {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	height: 140px;
	padding: 0 20px;
	width: 85%;
	position: relative;
}
.product .product-content .coupon-applied-notice {
	position: absolute;
	right: 14px;
	bottom: 0;
	background: #53b5aa;
	color: white;
	border-left: 4px solid #46a096;
	border-right: 4px solid #46a096;
	border-top: 4px solid #46a096;
	padding: 4px 10px;
}

.product h1 {
	color: #53b5aa;
	font-size: 25px;
	font-weight: 300;
	margin: 17px 0 20px 0;
}

.product footer.product-content {
	height: 49px;
	margin: 0;
	padding: 0;
}

.product footer .price {
	background: #fcfcfc;
	color: #000;
	float: right;
	font-size: 15px;
	font-weight: 300;
	line-height: 49px;
	margin: 0;
	padding: 0 10px;
}

.product footer .full-price {
	background: #53b5aa;
	color: #fff;
	float: right;
	font-size: 22px;
	font-weight: 300;
	line-height: 49px;
	margin: 0;
	padding: 0 10px;

	-webkit-transition: margin 0.15s linear;
	-moz-transition: margin 0.15s linear;
	-ms-transition: margin 0.15s linear;
	-o-transition: margin 0.15s linear;
	transition: margin 0.15s linear;
}

.qt,
.qt-plus,
.qt-minus {
	display: block;
	float: left;
}

.qt {
	font-size: 19px;
	line-height: 50px;
	width: 50px;
	text-align: center;
}

.qt-plus,
.qt-minus {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	background: #fcfcfc;
	border: none;
	font-size: 30px;
	font-weight: 300;
	height: 100%;
	padding: 0 20px;
	-webkit-transition: background 0.2s linear;
	-moz-transition: background 0.2s linear;
	-ms-transition: background 0.2s linear;
	-o-transition: background 0.2s linear;
	transition: background 0.2s linear;
}

.qt-plus:hover,
.qt-minus:hover {
	background: black;
	color: #fff;
	cursor: pointer;
}

.qt-plus {
	line-height: 50px;
}

.qt-minus {
	line-height: 50px;
}

#site-footer {
	margin: 30px 0 0 0;
}

#site-footer {
	padding: 40px;
}

#site-footer h1 {
	background: #fcfcfc;
	border: 1px solid #ccc;
	border-style: none none solid none;
	font-size: 24px;
	font-weight: 300;
	margin: 0 0 7px 0;
	padding: 14px 40px;
	text-align: center;
}

#site-footer h2 {
	font-size: 24px;
	font-weight: 300;
	margin: 10px 0 0 0;
}

#site-footer h3 {
	font-size: 19px;
	font-weight: 300;
	margin: 15px 0;
}

.left {
	float: left;
}

.right {
	float: right;
}

.btn {
	cursor: pointer;
	text-align: center;

	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.type {
	background: #fcfcfc;
	font-size: 13px;
	padding: 10px 16px;
	left: 100%;
}

.type,
.color {
	border: 1px solid #ccc;
	border-style: none none solid none;
	position: absolute;
}

.color {
	width: 40px;
	height: 40px;
	right: -40px;
}

.red {
	background: #cb5a5e;
}

.yellow {
	background: #f1c40f;
}

.minused {
	margin: 0 50px 0 0 !important;
}

.added {
	margin: 0 -50px 0 0 !important;
}
.product h5 {
	line-height: 50px;
	margin: 0;
	font-size: 30px;
	padding: 0 25px;
}
.breadcrumb li span {
	background: rgba(0, 0, 0, 0)
		url("https://ssl-images.newegg.com/WebResource/Themes/2005/Nest/bg_breadcrumbs.png")
		no-repeat scroll right center;
	color: #222;
	display: block;
	float: left;
	height: 34px;
	line-height: 34px;
	padding: 0 25px 0 15px;
	position: relative;
	text-decoration: none;
	z-index: 2;
}
.breadcrumb li.current span {
	background: rgba(0, 0, 0, 0)
		url("https://ssl-images.newegg.com/WebResource/Themes/2005/Nest/bg_breadcrumbs_current.png")
		no-repeat scroll right center;
	color: #fff;
	margin-left: -15px;
	padding-left: 30px;
	padding-right: 30px;
	position: relative;
	z-index: 1;
}

.breadcrumb {
	background: rgba(0, 0, 0, 0)
		linear-gradient(to bottom, #fafafa 0px, #eee 100%) repeat scroll 0 0;
	border: 1px solid #ddd;
	font-size: 17px;
	font-weight: 700;
	list-style: outside none none;
	overflow: hidden;
	padding: 0;
	text-transform: uppercase;
}
.breadcrumb > li {
	float: left !important;
}
.breadcrumb > li + li::before {
	content: none;
	padding: 0;
}
.fly-popout {
	position: absolute;
	left: 5%;
	top: 60px;
	background: white;
	width: 90%;
	display: none;
	padding: 5px;
	-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
		0 0 40px rgba(0, 0, 0, 0.1) inset;
	-moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
		0 0 40px rgba(0, 0, 0, 0.1) inset;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.fly-popout:before,
.fly-popout:after {
	content: "";
	position: absolute;
	z-index: -1;
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
	-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
	bottom: 0;
	left: 10px;
	right: 10px;
	-moz-border-radius: 100px / 10px;
	border-radius: 100px / 10px;
}
.fly-popout:after {
	right: 10px;
	left: auto;
	-webkit-transform: skew(8deg) rotate(3deg);
	-moz-transform: skew(8deg) rotate(3deg);
	-ms-transform: skew(8deg) rotate(3deg);
	-o-transform: skew(8deg) rotate(3deg);
	transform: skew(8deg) rotate(3deg);
}
.datapager {
	padding-left: 0px;
}
#products-container {
	padding-left: 0px;
}
.products-padding {
	padding-bottom: 25px;
}
.thumbnails {
	padding: 0;
	float: left;
	width: 15%;
	padding-right: 10px;
}
.thumbnails li {
	list-style: none;
}
.thumbnails li img {
	width: 100%;
	margin-bottom: 10px;
}
.easyzoom {
	float: left;
	width: 85%;
}
.productTitle {
	font-size: 26px;
	font-family: "Montserrat", sans-serif;
	color: rgb(14, 118, 188);
	font-weight: 700;
	line-height: 1.2;
	margin: 0 25px;
}
.totalContainer {
	padding-left: 0px;
	padding-right: 0 !important;
	margin: 20px 0;
}
.totalContainer footer {
	width: 100%;
	border: 1px solid #686868;
}
.totalContainer h5 {
	margin: 0;
	line-height: 50px;
	font-weight: bold;
}
.totalPrice {
	background: #53b5aa;
	color: #fff;
	float: right;
	font-size: 22px;
	font-weight: 300;
	line-height: 50px;
	margin: 0;
	padding: 0 10px;
}
.billPadding {
	padding: 0;
}
.form-group {
	margin-bottom: 5px;
}
.billFixPadding {
	padding-left: 0px;
}
@media screen and (min-width: 768px) {
	/* For destop: */
	.datapager {
		padding-left: 20px;
	}
	#products-container {
		padding-left: 20px;
	}
	.products-padding {
		padding: 0 10px 20px 0;
	}
	.totalContainer footer {
		width: 55%;
	}
	.totalContainer {
		padding-left: 60px;
		margin: 0;
	}
	.billPadding {
		padding: 10px 0 10px 10px;
	}
	.billFixPadding {
		padding-left: 10px;
	}
	.card.horizontal {
		display: flex;
	}
}
